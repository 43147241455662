import * as React from 'react'

import * as SelectPrimitive from '@radix-ui/react-select'
import { ChevronDown, ChevronUp } from 'lucide-react'

import { cn } from '@design-system/lib/utils'

import { Label } from '../label/label'
import { Text } from '../typography/text'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

type SelectTriggerProps = React.ComponentPropsWithoutRef<
  typeof SelectPrimitive.Trigger
> & {
  label?: string
  id: string
  hint?: React.ComponentType
  error?: string | string[]
}

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ className, children, id, label, error, hint, ...props }, ref) => (
  <div className={cn('flex flex-col-reverse', className)}>
    {error && (
      <Text className="mt-xs text-error" variant="p-s">
        {error}
      </Text>
    )}
    <SelectPrimitive.Trigger
      className={cn(
        'peer flex min-h-8 w-full items-center justify-between truncate border-b border-input p-xs text-left hover:border-input-hover focus:outline-none focus-visible:border-primary disabled:cursor-not-allowed disabled:opacity-50 data-[placeholder]:mt-0 data-[placeholder]:whitespace-nowrap data-[placeholder]:text-input data-[placeholder]:font-input-s',
        error && 'border-error',
      )}
      id={id}
      ref={ref}
      {...props}
    >
      {children}
      <SelectPrimitive.Icon asChild>
        <ChevronDown className="size-4 min-h-4 min-w-4 opacity-50" />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
    {label && (
      <Label hint={hint} htmlFor={id}>
        {label}
      </Label>
    )}
  </div>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    className={cn(
      'flex cursor-default items-center justify-center py-1',
      className,
    )}
    ref={ref}
    {...props}
  >
    <ChevronUp size={16} />
  </SelectPrimitive.ScrollUpButton>
))
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  Omit<
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>,
    'className'
  >
>((props, ref) => (
  <SelectPrimitive.ScrollDownButton
    className="flex cursor-default items-center justify-center py-xs"
    ref={ref}
    {...props}
  >
    <ChevronDown className="h-4 w-4" />
  </SelectPrimitive.ScrollDownButton>
))
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Content
    className={cn(
      'text-popover-foreground relative z-50 max-h-96 min-w-32 overflow-hidden rounded-md border bg-surface-card shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
      position === 'popper' &&
        'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
      className,
    )}
    position={position}
    ref={ref}
    {...props}
  >
    <SelectScrollUpButton />
    <SelectPrimitive.Viewport
      className={cn(
        'p-1',
        position === 'popper' &&
          'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
      )}
    >
      {children}
    </SelectPrimitive.Viewport>
    <SelectScrollDownButton />
  </SelectPrimitive.Content>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectItemText = ({ children }: { children: React.ReactNode }) => (
  <SelectPrimitive.ItemText>
    {typeof children === 'string' ? (
      <Text className="line-clamp-1" variant="p-s">
        {children}
      </Text>
    ) : (
      children
    )}
  </SelectPrimitive.ItemText>
)

SelectItemText.displayName = 'SelectItemText'

/**
 * __SelectLabel__
 *
 * Used in Select group menu to display a label for the group eg.
 *
 * @example
 * ```tsx
 * <SelectGroup>
 *  <SelectLabel>Group Label</SelectLabel>
 *  <SelectItem>Item 1</SelectItem>
 *  <SelectItem>Item 2</SelectItem>
 * </SelectGroup>
 * ```
 */
const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  Omit<
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>,
    'className'
  >
>((props, ref) => (
  <SelectPrimitive.Label
    className="px-s py-xs text-subtle font-text-xs"
    ref={ref}
    {...props}
  />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    className={cn(
      'relative flex w-full cursor-default select-none items-center rounded-sm p-s outline-none hover:cursor-pointer hover:bg-neutral-hover focus-visible:bg-neutral-hover data-[disabled]:pointer-events-none data-[state=checked]:text-primary data-[disabled]:opacity-50',
      className,
    )}
    ref={ref}
    {...props}
  >
    <SelectPrimitive.ItemIndicator className="absolute -left-xs bottom-0 top-0 border-l-4 border-primary" />

    {typeof children === 'string' ? (
      <SelectItemText>{children}</SelectItemText>
    ) : (
      children
    )}
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    className={cn('-mx-1 my-1 h-px bg-disabled', className)}
    ref={ref}
    {...props}
  />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectItemText,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton,
}
