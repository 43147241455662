import * as React from 'react'

import * as LabelPrimitive from '@radix-ui/react-label'

import { cn } from '@design-system/lib/utils'

const labelVariantClassName =
  'text-subtle font-label peer-disabled:cursor-not-allowed peer-disabled:opacity-70 peer-focus:text-primary'

export interface LabelProps {
  hint?: React.ComponentType
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & LabelProps
>(({ className, hint: Hint, ...props }, ref) => {
  if (!Hint)
    return (
      <LabelPrimitive.Root
        className={cn(labelVariantClassName, className)}
        ref={ref}
        {...props}
      />
    )
  return (
    <div
      className={cn(
        'flex items-center gap-x-xs hover:cursor-pointer peer-focus:[&>label]:text-primary',
        className,
      )}
    >
      <LabelPrimitive.Root
        className={cn(labelVariantClassName)}
        ref={ref}
        {...props}
      />
      <Hint />
    </div>
  )
})
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
