import * as React from 'react'

import { HelpCircle } from 'lucide-react'

import { cn } from '@design-system/lib/utils'

import { Tooltip, TooltipTrigger, TooltipContent } from '../tooltip/tooltip'
import { Text } from '../typography/text'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | string[]
  hint?: React.ComponentType
  icon?: React.ComponentType<{
    size?: string | number
    className?: React.InputHTMLAttributes<HTMLInputElement>['className']
  }>
  label?: string
}

/**
 * __Input__
 *
 * Use for text / search / email / password / etc. inputs.
 *
 * @template Built from shadcn/ui Badge component
 * @see https://ui.shadcn.com/docs/components/input
 *
 * @template Design
 * @see https://www.figma.com/file/qbcszGehTivEsGAzxFdrDN/OpTech-%2F-Design-System?type=design&node-id=874-44413&mode=dev
 *
 * @example
 * <Input
 *  label="Email"
 *  id="email"
 *  type="email"
 *  placeholder="Enter your email"
 *  icon={Email}
 * />
 */
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, label, icon: Icon, hint: Hint, error, id, ...props },
    ref,
  ) => {
    return (
      <div className={cn('group inline-flex w-full flex-col', className)}>
        {label && (
          <div className="flex items-center gap-x-1">
            <label
              className="text-subtle transition-colors duration-200 font-input-s group-has-[:focus-visible]:text-primary group-has-[:disabled]:opacity-50"
              htmlFor={id}
            >
              {label}
            </label>
            {Hint && <Hint />}
          </div>
        )}
        <div className="relative flex w-full flex-1 items-center">
          <div className="absolute left-1">
            {Icon && (
              <Icon
                className="text-input transition-colors duration-200 group-hover:text-subtle group-has-[:focus-visible]:text-primary group-has-[:disabled]:opacity-50"
                size={16}
              />
            )}
          </div>
          <input
            className={cn(
              `max-h-8 w-full border-b border-input bg-input py-s transition-colors duration-200 font-input-s placeholder:text-input hover:border-input-hover focus-visible:border-primary focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 group-hover:disabled:border-input`,
              error && 'text-error',
              Icon ? 'pl-7 pr-1' : 'px-1',
            )}
            id={id}
            ref={ref}
            style={{ marginBlockEnd: 0 }}
            type={type}
            {...props}
          />
        </div>
        {error && (
          <Text className="mt-xs" color={'error'} variant={'p-s'}>
            {error}
          </Text>
        )}
      </div>
    )
  },
)
Input.displayName = 'Input'

const InputHint = ({ children }: { children: React.ReactNode }) => (
  <Tooltip>
    <TooltipTrigger asChild>
      <HelpCircle className="shrink-0 text-input" size={12} />
    </TooltipTrigger>
    <TooltipContent>{children}</TooltipContent>
  </Tooltip>
)

export { Input, InputHint }
