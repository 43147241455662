import * as React from 'react'

import { cn } from '@design-system/lib/utils'

export interface CardProps {
  appearance?: 'default' | 'ai'
  variant?: 'raised' | 'flat'
}

/**
 * __Card__
 *
 * Use for workflows / insights / actions / data etc. Unopinionated card component with Header, Content and Footer.
 *
 * @template Built from shadcn/ui Badge component 'https://ui.shadcn.com/docs/components/card'
 *
 * @see 'https://www.figma.com/file/qbcszGehTivEsGAzxFdrDN/OpTech-%2F-Design-System?type=design&node-id=427-780&mode=design'
 *
 * @example
 * <Card>
 *    <CardHeader>Header</CardHeader>
 *    <CardContent>Content</CardContent>
 *    <CardFooter>Footer</CardFooter>
 * </Card>
 */
const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & CardProps
>(
  (
    { className, variant = 'raised', appearance = 'default', ...props },
    ref,
  ) => (
    <div
      className={cn(
        'rounded border bg-surface-card',
        variant === 'raised' && 'shadow-sm',
        appearance === 'ai' &&
          'bg-gradient-to-r from-primary-start/10 to-primary-stop/10',
        className,
      )}
      ref={ref}
      {...props}
    />
  ),
)
Card.displayName = 'Card'

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div className={cn('p-4 pb-3', className)} ref={ref} {...props} />
))
CardHeader.displayName = 'CardHeader'

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div className={cn('px-l pb-m pt-0', className)} ref={ref} {...props} />
))
CardContent.displayName = 'CardContent'

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div className={cn('p-4 pt-0', className)} ref={ref} {...props} />
))
CardFooter.displayName = 'CardFooter'

export { Card, CardHeader, CardFooter, CardContent }
