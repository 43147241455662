/* eslint-disable import/no-named-as-default */

import Heading from '@tiptap/extension-heading'
import {
  EditorProvider,
  mergeAttributes,
  useCurrentEditor,
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import {
  Bold,
  Heading1,
  Heading2,
  Heading3,
  Italic,
  List,
  ListOrdered,
  Strikethrough,
} from 'lucide-react'

import { cn } from '../../../lib/utils'
import { Toggle } from '../toggle/toggle'

// define your extension array
const extensions = [
  StarterKit.configure({
    heading: false,
    bulletList: {
      HTMLAttributes: {
        class: 'list-disc ml-8',
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: 'list-decimal ml-8',
      },
    },
  }),
  Heading.extend({
    levels: [1, 2],
    renderHTML({ node, HTMLAttributes }) {
      const level = this.options.levels.includes(node.attrs.level)
        ? node.attrs.level
        : this.options.levels[0]
      const classes: { [index: number]: string } = {
        1: 'font-heading-h2',
        2: 'font-heading-h3',
        3: 'font-heading-h4',
      }
      return [
        `h${level}`,
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          class: `${classes[level]}`,
        }),
        0,
      ]
    },
  }).configure({ levels: [1, 2, 3] }),
]

// menubar
const MenuBar = () => {
  const { editor } = useCurrentEditor()

  if (!editor) {
    return null
  }

  return (
    <div className="mb-4 flex h-fit w-fit items-center rounded-md border">
      <div className="p-xs">
        <Toggle
          disabled={!editor.can().chain().focus().toggleBold().run()}
          onPressedChange={() => editor.chain().focus().toggleBold().run()}
          pressed={editor.isActive('bold')}
          size="sm"
        >
          <Bold size={16} />
        </Toggle>
        <Toggle
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          onPressedChange={() => editor.chain().focus().toggleItalic().run()}
          pressed={editor.isActive('italic')}
          size="sm"
        >
          <Italic size={16} />
        </Toggle>
        <Toggle
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          onPressedChange={() => editor.chain().focus().toggleStrike().run()}
          pressed={editor.isActive('strike')}
          size="sm"
        >
          <Strikethrough size={16} />
        </Toggle>
      </div>
      <div className="border-l p-xs">
        <Toggle
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          pressed={editor.isActive('heading', { level: 1 })}
          size="sm"
        >
          <Heading1 size={16} />
        </Toggle>
        <Toggle
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          pressed={editor.isActive('heading', { level: 2 })}
          size="sm"
        >
          <Heading2 size={16} />
        </Toggle>
        <Toggle
          onPressedChange={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          pressed={editor.isActive('heading', { level: 3 })}
          size="sm"
        >
          <Heading3 size={16} />
        </Toggle>
      </div>
      <div className="border-l p-1">
        <Toggle
          onPressedChange={() =>
            editor.chain().focus().toggleBulletList().run()
          }
          pressed={editor.isActive('bulletList')}
          size="sm"
        >
          <List size={16} />
        </Toggle>
        <Toggle
          onPressedChange={() =>
            editor.chain().focus().toggleOrderedList().run()
          }
          pressed={editor.isActive('orderedList')}
          size="sm"
        >
          <ListOrdered size={16} />
        </Toggle>
      </div>
    </div>
  )
}

const RichTextEditor = ({
  text,
  onChange,
  heightRem,
  className,
}: {
  text: string | undefined
  onChange: (richText: string) => void
  heightRem?: string
  className?: string
}) => {
  const editorProps = {
    attributes: {
      class: cn([
        'resize-none prose-sm bg-input w-full h-[24rem] overflow-y-auto rounded-md border py-m outline-none font-text-s placeholder:text-input hover:border-input disabled:cursor-not-allowed disabled:opacity-50 px-l focus-visible:ring focus-visible:ring-primary gap-x-2',
        heightRem ? `h-[${heightRem}rem]` : 'h-[24rem]',
        className,
      ]),
    },
  }

  return (
    <EditorProvider
      content={text}
      editorProps={editorProps}
      extensions={extensions}
      onUpdate={({ editor }) =>
        onChange(!editor.isEmpty ? editor.getHTML() : '')
      }
      slotBefore={<MenuBar />}
    >
      <span />
    </EditorProvider>
  )
}

export { RichTextEditor }
