import * as React from 'react'

import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

/**
 * Optional separator types:
 * For use when the separator needs to expand beyond its parent container.
 */
const separatorVariants = cva('shrink-0 bg-neutral', {
  variants: {
    extend: {
      xl: '-mx-xl w-auto',
      l: '-mx-l w-auto',
    },
  },
})

export interface SeparatorProps
  extends React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>,
    VariantProps<typeof separatorVariants> {}

const Separator = React.forwardRef<
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  SeparatorProps
>(
  (
    {
      className,
      orientation = 'horizontal',
      decorative = true,
      extend,
      ...props
    },
    ref,
  ) => (
    <SeparatorPrimitive.Root
      className={cn(
        orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]',
        separatorVariants({ extend }),
        className,
      )}
      decorative={decorative}
      orientation={orientation}
      ref={ref}
      {...props}
    />
  ),
)
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
